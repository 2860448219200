<template>
  <section id="mainContainer" class="main-container">
    <el-container>
      <el-header height="8vh">
        <Header v-if="!noHeader" />
      </el-header>
      <el-container>
        <el-main>
          <div class="content-wrap">
            <div class="title" v-html="contentData.title"></div>
            <div
              v-for="(contents, i) in contentData.contents"
              :key="i"
              class="content-item"
            >
              <div class="sub-title">{{ contents.subTitle }}</div>
              <p v-for="(item, j) in contents.list" :key="j">{{ item }}</p>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </section>
</template>
<script>
import Header from "@/components/common/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      isLogin: false,
      noHeader: false,
      noFoot: false,
      hasTopBanner: false,
      hasPrevious: false,
      scale: 0,
      type: "",
      contentData: {},
    };
  },
  watch: {
    $route(val) {
      if (val) {
        this.isLogin = this.$route.meta.isLogin;
        this.noHeader = val.meta.noHeader;
        this.noFoot = val.meta.noFoot;
        this.hasTopBanner = val.meta.hasTopBanner;
        this.hasPrevious = val.meta.hasPrevious;
        this.hasSideBox = this.$route.meta.hasSideBox;
      }
    },
  },
  created() {
    this.isLogin = this.$route.meta.isLogin;
    this.noHeader = this.$route.meta.noHeader;
    this.noFoot = this.$route.meta.noFoot;
    this.hasTopBanner = this.$route.meta.hasTopBanner;
    this.hasPrevious = this.$route.meta.hasPrevious;
    this.hasSideBox = this.$route.meta.hasSideBox;
    this.type = this.$route.query.type;
    if (this.type == "privacy") {
      this.contentData.title = "";
      this.contentData.contents = [
        {
          subTitle: "",
          list: [
            
          ],
        },
      ];
    } else if (this.type == "service") {
    }
  },
};
</script>
<style lang="scss" scoped>
:deep() .el-header {
  position: relative;
  background-color: #ffffff;
  width: 100%;
  padding: 0;
  min-height: 60px;
}
:deep() .el-main {
  position: relative;
  width: 100%;
  min-height: 92vh;
  padding: 23px 18px;
}

.content-wrap {
  padding: 20px 80px 80px;
  .title {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 45px;
  }
  .sub-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
  p {
    font-size: 20px;
    padding: 0;
    margin: 0;
    line-height: 30px;
    text-indent: 39px;
  }
  .content-item {
    margin-bottom: 10px;
  }
}
</style>